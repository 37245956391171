export default {
    TableHeader: {
        UserName: 'User Name',
        Email: 'Email',
        Status: 'Status'
    },
    ADD_TESTER: 'Add Tester',
    EMAIL: "Email",
    Submit: "Submit",
    Cancel: "Cancel",
}

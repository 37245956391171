<template>
  <div id="active-project">
    <div class="active-project-body">
      <img class="mx-auto mt-10" :src="require('@/assets/images/logo.png')">
      <p class="text-orange-600 text-center mt-5">No projects is activated, please select the project you want to activate or skip!</p>
      <p class="text-orange-600 text-center mt-5">You can select {{ availableProjectUsage }} project to active</p>
      <div class="list-project">
        <!-- <div class="title mt-8 text-4xl">
          <span class="underline decoration-2">Click to select project</span>
        </div> -->
        <!-- <div class="mt-2 note text-ml text-center">* Please select one project to active or skip</div> -->
        <div class="projects-btn mt-10">
          <div @click="toggleActiveProject(project)" class="project-btn" :class="{'in-active': !project.isActive}" v-for="(project, idx) in listProject" :key="idx">
            <div class="blank-space w-[40px]"></div>
            <span>{{ project.projectName }}</span>
            <template v-if="!project.isActive">
              <div class="inActive-checkbox">
              </div>
            </template>
            <template v-else>
              <div class="active-checkbox">
                <img :src="require('@/assets/images/active-checkbox.svg')">
              </div>
            </template>
          </div>
        </div>
        <div class="submit-active-project mt-10 flex flex-row justify-center">
          <button class="magic-point-btn mx-auto text-xl !px-8 !py-4" @click="submitActiveProject()">
            Submit
          </button>
          <button class="magic-point-btn mx-auto text-xl !px-8 !py-4" @click="skipActiveProject()">
            Skip
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toast } from 'vue3-toastify'
import { ref, computed, onMounted } from 'vue'
import requestAsync from '/src/request/request.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const router = useRouter()
const store = useStore()
let availableProjectUsage = ref(0)
let userInfor = computed(() => {
  return store.getters['user/getUserInfo']
}).value
let listProject = ref([])
async function getListProject() {
  listProject.value = []
  const res = await requestAsync.get('projects/user/' + userInfor?.userId)
  if (!res?.hasError && res?.appData) {
    if (res?.appData?.length > 0) {
      let listProjectRes = res?.appData
      for(let project of listProjectRes){
        if(project.userId === userInfor?.userId){
          listProject.value.push(project)
        }
      }
      store.commit('projects/setListProject', res?.appData)
    }
  }
}
function toggleActiveProject(project){
  project.isActive = !project.isActive
}
async function submitActiveProject(){
  let update = false;
  let numberOfActiveProject = 0
  for(let project of listProject.value){
    if(project.isActive){
      numberOfActiveProject++
      update = true
    }
  }

  if(numberOfActiveProject > availableProjectUsage.value){
    toast.info("Can only active " + availableProjectUsage.value + " project for your plan")
    return
  }

  if(!update){
    toast.info("No projects active!\nYou can skip or select project to active again")
    return
  }

  let reqData = {
    appData: listProject.value
  }
  const res = await requestAsync.put('projects/active-status', reqData)
  if(res){
    toast.success('Active projects success!\nRedirecting to projects page')
    setTimeout(()=> {
      router.push('/select-project')
    }, 1500)
  }
  else{
    toast.error('Active projects fail!')
  }
}
async function skipActiveProject(){
  router.push('/select-project')
}
async function getPlanUsage(){
  const res = await requestAsync.get('user-plan/' + userInfor?.userId)

  if(res?.appData){
    availableProjectUsage.value = res.appData.limitProject - res.appData.usedProject
  }
}
onMounted(() => {
  getListProject()
  getPlanUsage()
})
</script>

<style lang="scss">
@import url("@/assets/variables.css");
#active-project{
  width: 70%;
  margin: 0px auto;
  .active-project-body{
    .list-project{
      .title{
        text-align: center;
      }
      .projects-btn{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .project-btn{
          width: 30%;
          border: solid 2px var(--main-theme-color);
          border-radius: 3px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          font-size: 24px;
          background: var(--main-theme-color);
          color: white;
          .inActive-checkbox{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: solid 2px var(--main-theme-color);
          }
          .active-checkbox{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: solid 1px #fff;
          }
        }
        :hover{
            font-size: 26px;
            transition-duration: 0.5s;
          }
        .in-active{

          background: #fff;
          color: var(--main-theme-color);
        }
      }
    }
  }
}
</style>

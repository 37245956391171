<template>
<div class="w-full max-w-md p-6 mx-auto mt-32 login-form">
  <div class="logo">
    <img class="mx-auto" :src="require('@/assets/images/logo.png')">
    <div class="h-12 text-center flex flex-col justify-end" :class="{ invisible: errorMsg.length === 0 }">
    </div>
  </div>
  <h4 class="text-xl mt-6 title">{{ $t('login.LOGIN_TITLE') }}</h4>
  <div class="mt-3">
    <div class="mb-6">
      <label for="email" class="block mb-2 text-xl font-medium dark:text-white">{{ $t('login.EMAIL_LABEL') }}</label>
      <input v-model="emailInput" type="email" id="email"
        class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Please enter your mail" required />
    </div>
    <div class="mb-6">
      <label for="password" class="block mb-2 text-xl font-medium text-gray-900 dark:text-white">{{
      $t('login.PASSWORD_LABEL') }}</label>
      <input v-model="password" type="password" id="password"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        required />
    </div>
    <div class="h-8 text-center flex flex-col justify-end" :class="{ invisible: errorMsg.length === 0 }">
      <span class="text-rose-600">{{ errorMsg }}</span>
    </div>
    <div class="flex justify-between mt-4 items-end">
      <button @click="submitForm" type="submit"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-10 py-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        Login
      </button>
      <router-link to="/forget-password" class="">{{
      $t('login.FORGOT_PASSWORD')
        }}</router-link>
    </div>
  </div>
</div>
</template>
<script setup>
// import { FwbButton } from 'flowbite-vue'
import { validateEmail } from '/src/ultils/validateData.js'
import { useRouter } from 'vue-router'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import requestAsync from '/src/request/request.js'
import { useStore } from 'vuex'
const store = useStore()

let emailInput = ref('')
let password = ref('')
let errorMsg = ref('')
const router = useRouter()
let userId = null
async function submitForm() {
  const validateInputEmail = validateEmail(emailInput.value)
  let reqData = {
    appData: {
      email: emailInput.value,
      password: password.value,
    },
  }
  if (!validateInputEmail) {
    errorMsg.value = 'This email not valid'
  } else {
    const res = await requestAsync.post('user/login', reqData)
    if (!res?.hasError && res?.appData) {
      userId = res?.appData?.userId
      store.commit('user/setUserId', res?.appData?.userId)
      store.commit('user/setToken', res?.appData?.authToken)
      store.commit('user/setRole', res?.appData?.role)
      await getListProject()
    } else {
      errorMsg.value = 'Login fail! Please try again!'
      console.log(errorMsg)
    }
  }
}
const submitOnEnter = (event) => {
  if (event.key === "Enter") {
    submitForm()
  }
}
onMounted(() => {
  window.addEventListener('keypress', submitOnEnter)
})

onBeforeUnmount(() => {
  window.removeEventListener('keypress', submitOnEnter)
})
async function getListProject() {
  let listProject = []
  const res = await requestAsync.get('projects/user/' + userId)
  if (!res?.hasError && res?.appData) {
    if (res?.appData?.length > 0) {
      listProject = res?.appData

      const allInActive = listProject
          .filter(project => project.userId === userId)
          .every(project => !project.isActive);

      const haveOwnerProject = listProject.some(project => project.userId === userId);

      // if user is owner of some project and all project is inactive
      if (allInActive && haveOwnerProject) {
        router.push('/active-project');
      }
    }
    router.push('/select-project');
  }
}
</script>
<style lang="scss" scoped>
.login-form {
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  box-shadow: 0 5px 30px #f2d7d7b3;
  height: 520px;

  .title {
    color: #0B60B0;
  }

  label {
    color: #0B60B0;
  }
}
</style>

const initialState = {
  'userId': 0,
  'authToken': null,
  'role': null
}

const localState = localStorage.getItem('user')
if (localState) {
  Object.assign(initialState, JSON.parse(localState))
}
const getters = {
  getUserInfo: (state) => state,
  getToken: (state) => state.authToken,
  getRole: (state) => state.role,
}
const mutations = {
  setUserId: (state, IdReq) => {
    state.userId = IdReq
    localStorage.setItem('user', JSON.stringify(state))
  },
  setToken: (state, authToken) => {
    state.authToken = authToken
    console.log(state)
    localStorage.setItem('user', JSON.stringify(state))
  },
  setRole: (state, role) => {
    state.role = role
    localStorage.setItem('user', JSON.stringify(state))
  }
}
export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
}

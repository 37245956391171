<script setup>
import { computed } from 'vue'
import LeftNavigation from './views/LeftNavigation.vue'
import HeaderView from './views/HeaderView.vue'
import LandingPage from './views/LandingPage.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const pagesOfLoginFlow = ['/login', '/forget-password', '/select-project', '/active-project']
const ROOT_ROUTE = '/'
const isNotLoginFlow = computed(() => {
  return !pagesOfLoginFlow.includes(route.path)
})
const isRootRoute = computed(() => {
  console.log('current route', route.path)
  console.log("is root route: ", isRootRoute)
  return route.path === ROOT_ROUTE
})
</script>

<template>
  <template v-if="isRootRoute">
    <LandingPage></LandingPage>
  </template>
  <div v-else class="h-full flex flex-col">
    <template v-if="isNotLoginFlow">
      <HeaderView></HeaderView>
    </template>
    <div class="h-full grow flex flex-row">
      <template v-if="isNotLoginFlow">
        <LeftNavigation></LeftNavigation>
      </template>
      <router-view />
    </div>
  </div>
</template>

<style>
@import url('assets/custom.css');
@import url('assets/variables.css');
</style>

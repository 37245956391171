<template>
  <header id="header">
    <nav class="w-100 max-h-24 p-3 bg-header flex justify-between">
      <div class="list-project flex flex-end">
        <select v-model="selectedProjectId">
          <option v-for="(project, id) in listProjectOption" :value="project.projectId" :key="id">
            {{ project.projectName }}
          </option>
        </select>
      </div>
      <img class="logo" :src="require('@/assets/images/logo.png')">
    </nav>
  </header>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
// const router = useRouter()
// import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
// import clearLocalStore from '/src/ultils/clearLocalStore.js'

let selectedProjectId = ref(
  store.getters['projects/getSelectedProjectId'] ||
    store.getters['projects/getListProject'].length > 0
    ? store.getters['projects/getListProject'][0]?.projectId
    : 0
)

watch(selectedProjectId, () => {
  store.commit('projects/setSelectedProjectId', selectedProjectId)

  let projectChange = listProjectOption.value.find(project => project.projectId === selectedProjectId.value)
  store.commit('projects/setSelectedProjectInfo', projectChange)
})
let listProjectOption = computed(() => {
  console.log(store.getters['projects/getListProject'])
  let res = store.getters['projects/getListProject']
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  selectedProjectId.value = store.getters['projects/getSelectedProjectId']
  return res
})
// function logout() {
//   store.commit('projects/clearData')
//   console.log('hehe header')
//   clearLocalStore()
//   router.push('/login')
// }
</script>
<style scoped>
.menu {
  border: solid 1px #fff;
  border-radius: 5px;
}

.item-menu {
  background: #fff;
  border-radius: 5px;
}

.form-select {
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  font-size: 1.25rem;
}
.logo{
  height: 60px;
  width: auto;
}
</style>

import { createI18n } from 'vue-i18n'
import en from './en'
import ja from './ja'
const messages = {
  en: en,
  ja: ja,
}
const i18n = createI18n({
  locale: 'ja', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})
export default i18n

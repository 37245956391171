<template>
  <section id="select-project-component" class="w-full flex flex-col">
    <div class="max-w-md mx-auto mt-32 mb-10">
      <h2 class="text-4xl text-center">Please select project</h2>
      <div class="list-project mt-5">
        <template v-for="(project, id) in listProject" :key="id">
          <div
            class="project-item my-1 py-4 text-center font-medium bg-main-theme text-white cursor-pointer rounded-md hover:duration-500 hover:text-xl hover:bg-dark-main-theme"
            @click="selectProject(project)"
          >
            {{ project.projectName }}
          </div>
        </template>
        <div
          v-if="canCreateNewProject"
          class="project-item my-1 py-4 font-medium text-center text-main-theme-color border-2 border-main-theme-color cursor-pointer rounded-md hover:duration-500 hover:text-xl hover:bg-dark-main-theme hover:text-white"
          @click="createNewProject()"
        >
          Create new project +
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import requestAsync from '/src/request/request.js'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const router = useRouter()

let listProject = ref([])
function selectProject(selectedProject) {
  console.log(selectedProject)
  store.commit('projects/setSelectedProjectId', selectedProject.projectId)
  store.commit('projects/setSelectedProjectInfo', selectedProject)
  goToProjectManagementPage()
}
function createNewProject() {
  store.commit('projects/setSelectedProjectId', null)
  store.commit('projects/setSelectedProjectInfo', null)
  goToProjectManagementPage()
}
function goToProjectManagementPage() {
  router.push('/project-management')
}
let userInfor = computed(() => {
  return store.getters['user/getUserInfo']
}).value
async function getListProject() {
  listProject.value = []
  const res = await requestAsync.get('projects/user/' + userInfor?.userId)
  if (!res?.hasError && res?.appData) {
    if (res?.appData?.length > 0) {
      listProject.value = res?.appData
      store.commit('projects/setListProject', res?.appData)
    }
  }
}
let canCreateNewProject = ref(true)
async function getPlanUsage(){
  const res = await requestAsync.get('user-plan/' + userInfor?.userId)
  if(res?.appData && parseInt(res?.appData?.limitProject) <= parseInt(res?.appData?.usedProject)){
    canCreateNewProject.value = false
  }
}
onMounted(async () => {
  await getListProject()
  await getPlanUsage()
})
</script>

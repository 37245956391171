<template >
    <section id="header" class="flex justify-between items-center px-2">
        <a href="#" class=" h-20">
            <img src="../../assets/images//Magic_point_Logo_V3.png" alt="" class=" max-h-20">
        </a>
        <nav class="flex items-center">
            <template :key="index" v-for="([title, url], index) of headerItems">
                <a class=" px-5 py-4 text-lp-main-color/50 hover:text-black m-2" :href=url>{{ title }}</a>
            </template>
            <!-- <div id="nav-item">Mission</div>
            <div id="nav-item">Ships</div>
            <div id="nav-item">Contact</div>
            <div id="nav-item">CCC</div> -->
            <div id="nav-item">
                <LandingPageButton :href="'https://chromewebstore.google.com/detail/magic-point/aneeijmdeahjkiegfhgngfbkcopllngo'" :buttonContent="'Try it out!!!'">
                </LandingPageButton>
            </div>
        </nav>
    </section>
</template>
<script setup>
import LandingPageButton from './LandingPageButton.vue';
const headerItems = [
    ['Features', '#features'],
    ['Reviews', '#social-proofs-section'],
    ['FAQs', '#faq-section'],
    ['Contacts', '#footer-section'],
]
</script>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
// import VPagination from '@hennge/vue3-pagination'
// import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import i18n from './i18n/index.js'
import './assets/main.css'
import 'flowbite/dist/flowbite.min.js';

import loading from 'vue3-loading-overlay';
// Import stylesheet
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

const toastOptions = {
  // Example configuration
  position: 'top-center',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
}

createApp(App)
  .use(Vue3Toastify, toastOptions)
  .use(store)
  .use(loading)
  .use(router)
  .use(i18n)
  .mount('#app')

<template>
    <section id="social-proofs-section" class="bg-slate-300 flex justify-center items-center">
        <div id="social-proofs" class="flex flex-col gap-24 items-center justify-center py-24 ">
            <h2 id="social-proofs_title" class=" font-bold text-4xl">Hear from our customers</h2>
            <div id="social-proofs_proof-container" class="flex gap-6">
                <template
                    v-for="({ avtUrl, customerPosition, customerQuote, customerCompany, customerName, option }, index) of cardInfos"
                    :key="index">
                    <ProofCard :avtUrl="avtUrl" :customer-quote="customerQuote" :customer-company="customerCompany"
                        :customer-name="customerName" :customer-position="customerPosition" :option="option || 'normal'">
                    </ProofCard>
                </template>
            </div>
        </div>
    </section>
</template>
<script setup>
import ProofCard from './ProofCard.vue'
import aidabot from '../../assets/images/landing-page/social-proof-avt/AIDABOT.png'
import sios from '../../assets/images/landing-page/social-proof-avt/sios-logo.webp'
import genki from '../../assets/images/landing-page/social-proof-avt/GK.png'
import fireAlarm from '../../assets/images/landing-page/social-proof-avt/Firealarm.webp'
const cardInfos = [
    { avtUrl: aidabot, customerPosition: 'Tester', customerCompany: 'AIdaBOT', customerName: 'Lam Nguyen Doan', customerQuote: 'Magic-Point streamlines UI testing flawlessly. With one click, it seamlessly creates tasks in our management tool. Easy to use, it enhances our workflow efficiency and overall software quality. Highly recommended!' },
    { option: 'smaller', avtUrl: sios, customerPosition: 'Manual Tester', customerCompany: 'SIOS Corp', customerName: 'Huong Pham', customerQuote: 'Magic-Point is a game-changer in UI testing, effortlessly integrating with our task management tool. Its intuitive interface enhances workflow efficiency, ensuring top-tier software quality. Highly recommend for seamless bug detection and task creation!' },
    { avtUrl: genki, customerPosition: 'Developer', customerCompany: 'PMS', customerName: 'Hao Nguyen Tran Anh', customerQuote: 'This is a customer quote. The customer is going to share their opinion about our product or service, hopefully it’s going to ve a positive one. The social proof section is important when you want to increase trust and trustworthiness of your company with your website visitors' },
    { avtUrl: fireAlarm, customerPosition: 'Product Manager', customerCompany: 'Genki System', customerName: 'Hoa Nguyen', customerQuote: 'This is a customer quote. The customer is going to share their opinion about our product or service, hopefully it’s going to ve a positive one. The social proof section is important when you want to increase trust and trustworthiness of your company with your website visitors' },
]
</script>

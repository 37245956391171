<template>
<section id="project-management" class="px-4 mt-3 flex flex-col">
  <div class="flex">
    <div class="flex flex-col justify-start gap-3">
      <div class="channel flex flex-row items-center">
        <div class="sub-title-name font-medium w-[160px] ">
          <span>{{ $t('projectManagement.PROJECT_NAME') }}</span>
        </div>
        <input :disabled="!isAllowToModify" :class="{ 'bg-slate-300': !isAllowToModify }"
               v-model="projectInfo.projectName" :placeholder="$t('projectManagement.PROJECT_NAME_PLACEHOLDER')" />
      </div>

      <!-- TODO: remove this after release 1.0 -->
      <!-- <div class="relative flex flex-row items-center">
        <span class="w-[160px] sub-title-name font-medium">{{ $t('projectManagement.API_KEY') }}</span>
        <input disabled v-model="projectInfo.apiKey" class="max-w-[203px] !pr-9 text-ellipsis"
          :class="{ 'bg-slate-300': !isAllowToModify }" />
        <img @click="generateAPIKey()" :class="{ loading: isLoading, 'cursor-pointer': isAllowToModify }"
          class="max-w-[20px] absolute top-3 right-12" :src="require('@/assets/images/reload-btn.svg')" />
        <img @click="copyAPIKey()" class="ml-2 max-w-[30px] cursor-pointer"
          :src="require('@/assets/images/copy-btn.svg')" />
      </div> -->
      <div class="relative flex flex-row items-center">
        <div class="w-[160px] sub-title-name font-medium">
          <span>{{ $t('projectManagement.ACTIVE_PROJECT') }}</span>
        </div>
        <ToggleButton class="flex flex-row" name="active-project" :isActive="projectInfo.isActive"
                      :disabled="!isAllowToModify || (projectInfo.isActive === false && availableProjectUsage === 0)" @update:isActive="changeStatusActiveProject()">
        </ToggleButton>
      </div>
    </div>
  </div>
  <hr class="my-3" />
  <div class="grid grid-cols-2">
    <div class="col-6">
      <template v-for="(item, id) in projectInfo.thirdPartyProjects" :key="id">
        <template v-if="item?.thirdPartyType === 'TASK'">
          <div class="status flex flex-col">
            <div class="title-name">
              <span>{{ $t('projectManagement.TASK_CHANNEL_CONFIGS') }}</span>
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.SELECTED_CHANNEL') }}</span>
              </div>
              <select :disabled="!isAllowToModify" v-model="item.thirdPartyService"
                      :class="{ 'bg-slate-300': !isAllowToModify }">
                <option v-for="(taskChannel, id) in taskChannels" :value="taskChannel.value" :key="id">
                  {{ taskChannel.text }}
                </option>
              </select>
            </div>
            <div class="status flex flex-row mt-3">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.ACTIVE_TASK') }}</span>
              </div>
              <ToggleButton name="active-project" :disabled="!isAllowToModify"
                :isActive="projectInfo.thirdPartyProjects[0].isActive" @update:isActive="changeStatus3rdParty('TASK')">
              </ToggleButton>
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.SPACE_ID') }}</span>
                <button data-popover-target="popover-space-id" type="button"
                  class="focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <img :src="require('@/assets/images/support-icon.svg')" alt="support_icon" class="ml-2 w-7">
                </button>
                <div data-popover id="popover-space-id" role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div
                    class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <p class="text-md font-bold text-center">How to get space Id?</p>
                  </div>
                  <div class="px-3 py-2">
                    <p>Click <a href="https://support.nulab.com/hc/en-us/articles/8598280601753"
                        class="underline">here</a> to view document, or you can get it in header like image below!</p>
                    <img :src="require('@/assets/images/space_id.png')">
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <input :disabled="!isAllowToModify" :class="{ ' bg-slate-300': !isAllowToModify }"
                     v-model="item.spaceId" />
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.PROJECT_KEY') }}</span>
                <button data-popover-target="popover-project-key" type="button"
                  class="focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <img :src="require('@/assets/images/support-icon.svg')" alt="support_icon" class="ml-2 w-7">
                </button>
                <div data-popover id="popover-project-key" role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div
                    class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <p class="text-md font-bold text-center">How to get project key?</p>
                  </div>
                  <div class="px-3 py-2">
                    <p>Click <a
                        href="https://support.nulab.com/hc/en-us/articles/8607057630745-Create-project-and-manage-general-settings"
                        class="underline">here</a> to view document, or you can get it in header like image below!</p>
                    <img :src="require('@/assets/images/project_key.png')">
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <input :disabled="!isAllowToModify" :class="{ ' bg-slate-300': !isAllowToModify }"
                     v-model="item.projectKey" />
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.USER_KEY') }}</span>
                <button data-popover-target="popover-user-key" type="button"
                  class="focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <img :src="require('@/assets/images/support-icon.svg')" alt="support_icon" class="ml-2 w-7">
                </button>
                <div data-popover id="popover-user-key" role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div
                    class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <p class="text-md font-bold text-center">How to get user key?</p>
                  </div>
                  <div class="px-3 py-2">
                    <p>1. Go to your project in selected channel</p>
                    <p>2. Click your icon in top right screen</p>
                    <p>3. Select <b>Personal setting</b></p>
                    <p>4. In left navigation select API</p>
                    <p>5. Input memo, click button <b>submit</b> to create new API keys</p>
                    <img :src="require('@/assets/images/user_key.png')" class="mt-2">
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <input :disabled="!isAllowToModify" :class="{ ' bg-slate-300': !isAllowToModify }"
                     v-model="item.userKey" />
            </div>
          </div>
        </template>
        <template v-if="item?.thirdPartyType === 'NOTIFICATION'">
          <div class="status flex flex-col mt-3">
            <div class="title-name">
              <span>{{
            $t('projectManagement.NOTIFICATION_CHANNEL_CONFIGS')
          }}</span>
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.SELECTED_CHANNEL') }}</span>
              </div>
              <select :class="{ ' bg-slate-300 ': !isAllowToModify }" :disabled="!isAllowToModify"
                      v-model="item.thirdPartyService">
                <option v-for="(notificationChannel, id) in notificationChannels" :value="notificationChannel.value"
                  :key="id">
                  {{ notificationChannel.text }}
                </option>
              </select>
            </div>
            <div class="status flex flex-row mt-3">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.ACTIVE_NOTIFICATION') }}</span>
              </div>
              <ToggleButton name="active-project" :disabled="!isAllowToModify"
                :isActive="projectInfo.thirdPartyProjects[1].isActive"
                @update:isActive="changeStatus3rdParty('NOTIFICATION')"></ToggleButton>
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.WEBHOOK_URL') }}</span>
                <button data-popover-target="popover-webhook-url" type="button"
                  class="focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <img :src="require('@/assets/images/support-icon.svg')" alt="support_icon" class="ml-2 w-7">
                </button>
                <div data-popover id="popover-webhook-url" role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div
                    class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <p class="text-md font-bold text-center">How to create Webhook URL?</p>
                  </div>
                  <div class="px-3 py-2">
                    <p>1. Go to your project in selected channel</p>
                    <p>2. Select server you want to connect</p>
                    <p>3. Click server name in top left screen, select <b>Server setting</b></p>
                    <p>4. Click <b>Integrations</b></p>
                    <p>5. Click <b>Webhooks</b> / <b>New Webhook</b></p>
                    <p>6. Edit infor of new Webhook</p>
                    <p>7. Click <b>Copy Webhook URL</b></p>
                    <img :src="require('@/assets/images/discord_hint.png')" class="mt-2">
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <input :disabled="!isAllowToModify" :class="{ ' bg-slate-300 ': !isAllowToModify }"
                     v-model="item.notiWebHookUrl" />
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="col-6">
      <template v-for="(item, id) in projectInfo.thirdPartyProjects" :key="id">
        <template v-if="item?.thirdPartyType === 'DESIGN'">
          <div class="status flex flex-col">
            <div class="title-name">
              <span>{{ $t('projectManagement.DESIGN_TOOL_CONFIGS') }}</span>
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.SELECTED_CHANNEL') }}</span>
              </div>
              <!-- <select
                  v-model="item.thirdPartyService"
                  :options="designTools"
                ></select> -->
              <select :disabled="!isAllowToModify" :class="{ ' bg-slate-300': !isAllowToModify }"
                      v-model="item.thirdPartyService">
                <option v-for="(designTool, id) in designTools" :value="designTool.value" :key="id">
                  {{ designTool.text }}
                </option>
              </select>
            </div>
            <div class="status flex flex-row mt-3">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.ACTIVE_NOTIFICATION') }}</span>
              </div>
              <ToggleButton name="active-project" :disabled="!isAllowToModify"
                :isActive="item.isActive"
                @update:isActive="changeStatus3rdParty('DESIGN')"></ToggleButton>
            </div>
            <div class="flex flex-row mt-2">
              <div class="sub-title-name flex items-center w-[160px]">
                <span>{{ $t('projectManagement.TEAM_ID') }}</span>
                <button data-popover-target="popover-team-id" type="button"
                  class="focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <img :src="require('@/assets/images/support-icon.svg')" alt="support_icon" class="ml-2 w-7">
                </button>
                <div data-popover id="popover-team-id" role="tooltip"
                  class="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                  <div
                    class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <p class="text-md font-bold text-center">How to get your team Id?</p>
                  </div>
                  <div class="px-3 py-2">
                    <p>1. Go to your project in selected channel</p>
                    <p>2. In left navigation / Teams, select your team you want connect</p>
                    <p>3. In header, you can see a <b>list number</b> like image below</p>
                    <p>4. Copy it and paste to here</p>
                    <img :src="require('@/assets/images/figma_hint.png')" class="mt-2">
                  </div>
                  <div data-popper-arrow></div>
                </div>
              </div>
              <div class="design-tool-ids">
                <template v-for="(toolId, id) in item.figmaTeamIds" :key="id">
                  <input :disabled="!isAllowToModify"
                    :class="{ 'mt-1': id !== 0, ' bg-slate-300': !isAllowToModify }"
                    v-model="item.figmaTeamIds[id]" />
                </template>
              </div>
              <div class="btn-add flex flex-col justify-end">
                <!-- <button class="ml-5 magic-point-btn h-[40px]" @click="addToolIdField">{{ $t("projectManagement.ADD_MORE_BTN") }}</button> -->
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
  <hr class="my-3" />
  <div class="mt-1 mb-4 mx-auto list-btn flex justify-between">
    <!-- <div class="space"></div> -->
    <button v-show="isAllowToModify" class="magic-point-btn" type="button" variant="success"
            @click="updateProjectInfor()" :disabled="!isAllowToModify"
            :class="{ '!bg-slate-300 cursor-default !hover:bg-slate-300': !isAllowToModify }">
      Submit
    </button>
    <!-- <input type="button" value="Add to favorites" /> -->
    <!-- <button type="button" class="magic-point-btn-cancel">Cancel</button> -->
  </div>
</section>
<fwb-modal persistent v-if="isOpenAddTesterModal">
  <template #header>
    <div class="flex items-center text-2xl text-center mx-auto">
      {{ $t('projectManagement.ADD_TESTER') }}
    </div>
  </template>
  <template #body>
    <div class="w-90 max-w-[500px] mx-auto flex justify-center items-center mr-20">
      <label for="email" class="inline-block text-xl font-medium text-gray-900 dark:text-white basis-1/6">{{
            $t('projectManagement.EMAIL') }}:</label>
      <input v-model="testerEmail" type="email" id="email"
        class="basis-4/6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        required />
    </div>
    <div class=" w-full flex justify-center items-center mt-2 transition-none duration-500 ease-out">
      <p :class="messageObj.isError ? `text-red-700 !opacity-100` : `text-green-700 !opacity-100`">{{
            messageObj.message
          }}</p>
    </div>
  </template>
  <template #footer>
    <div class="flex justify-between">
      <fwb-button @click="adminAddTester" class="magic-point-btn">
        Submit
      </fwb-button>
      <fwb-button @click="hideAddTesterModal" color="alternative">
        Cancel
      </fwb-button>
    </div>
  </template>
</fwb-modal>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue'
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'
import ToggleButton from '/src/components/common/ToggleButton.vue'
import requestAsync from '/src/request/request.js'
import { FwbButton, FwbModal } from 'flowbite-vue'
import { toast } from 'vue3-toastify'
import { useStore } from 'vuex'
import { isProjectOwner } from '@/ultils/validateData'
const store = useStore()
let projectInfo = ref({})
const designTools = [
  {
    text: 'Figma',
    value: 'FIGMA',
  },
  // {
  //     text: 'Adobe XD',
  //     value: 'Adobe XD'
  // },
]
const taskChannels = [
  {
    text: 'Nulab - Backlog',
    value: 'NULAB',
  },
  // {
  //     text: 'Jira - Backlog',
  //     value: 'JIRA'
  // },
]
let notificationChannels = [
  {
    text: 'Discord',
    value: 'DISCORD',
  },
  //   {
  //       text: 'Microsoft Team',
  //       value: 'Microsoft Team'
  //   },
]

function initProjectInfo() {
  projectInfo.value = {
    projectId: null,
    isActive: true,
    projectName: '',
    domain: '',
    apiKey: '',
    userId: userInfor?.userId,
    thirdPartyProjects: [
      {
        thirdPartyProjectId: null,
        spaceId: '',
        projectKey: '',
        isActive: true,
        thirdPartyService: 'NULAB',
        thirdPartyType: 'TASK',
        userKey: '',
        notiWebHookUrl: null,
        figmaTeamIds: null,
      },
      {
        thirdPartyProjectId: null,
        spaceId: null,
        projectKey: null,
        isActive: true,
        thirdPartyService: 'DISCORD',
        thirdPartyType: 'NOTIFICATION',
        userKey: null,
        notiWebHookUrl: '',
        figmaTeamIds: [],
      },
      {
        thirdPartyProjectId: null,
        spaceId: null,
        projectKey: null,
        isActive: true,
        thirdPartyService: 'FIGMA',
        thirdPartyType: 'DESIGN',
        userKey: null,
        notiWebHookUrl: null,
        figmaTeamIds: [''],
      },
    ],
  }
}
async function updateProjectInfor() {
  let reqData = {
    appData: projectInfo.value,
  }
  let res
  if (!selectedProjectId.value) {
    res = await requestAsync.post('projects', reqData)
    if (!res?.hasError && res?.appData) {
      store.commit('projects/setSelectedProjectId', res?.appData?.projectId)
      store.commit('projects/addProjectToList', res?.appData)
      store.commit('projects/setSelectedProjectInfo', res?.appData)
      getListProject()
      toast.success('Create project config success!')
    } else {
      toast.error('Create project config fail!')
    }
  } else {
    reqData.appData.projectId = selectedProjectId.value
    res = await requestAsync.put('projects', reqData)
    if (!res?.hasError && res?.appData) {
      getListProject()
      toast.success('Update project config success!')
    } else {
      toast.error('Update project config fail!')
    }
  }
}

let userInfor = computed(() => {
  return store.getters['user/getUserInfo']
}).value

let selectedProjectId = computed(() => {
  return store.getters['projects/getSelectedProjectId']
})

let selectedProjectInfo = computed(() => {
  return store.getters['projects/getSelectedProjectInfo']
})

let isAllowToModify = computed(() => {
  // handle null error when logout
  if(userInfor == null){
    return false
  }

  // new project, allow to modify
  if (selectedProjectInfo.value == null){
    return true
  }

  return isProjectOwner(userInfor?.userId, selectedProjectInfo?.value.userId)
})

async function getProjectInfoById() {
  if (selectedProjectId.value) {
    const res = await requestAsync.get('projects/' + selectedProjectId.value)
    if (!res?.hasError && res?.appData) {
      let designConfig = res?.appData?.thirdPartyProjects?.find((item) => {
        return item.thirdPartyType === 'DESIGN'
      })
      if (designConfig.figmaTeamIds.length <= 0) {
        designConfig.figmaTeamIds = ['']
      }
      projectInfo.value = res?.appData
    } else {
      initProjectInfo()
    }
  } else {
    initProjectInfo()
  }
}
async function getListProject() {
  const res = await requestAsync.get('projects/user/' + userInfor?.userId)
  if (!res?.hasError && res?.appData) {
    if (res?.appData?.length > 0) {
      store.commit('projects/setListProject', res?.appData)
    }
  } else {
    console.error('can not get list project')
  }
}

//TODO: remove this after release 1.0
// let isLoading = ref(false)
// async function generateAPIKey() {
//   if (!isAllowToModify.value) return
//   isLoading = true
//   const res = await requestAsync.post('projects/key')
//   if (!res?.hasError && res?.appData) {
//     toast.success('Generate new API key success!')
//     projectInfo.value.apiKey = res?.appData
//   } else {
//     toast.error('Generate new API key fail!')
//   }
//   isLoading = true
// }
// function copyAPIKey() {
//   if (navigator.clipboard) {
//     navigator.clipboard
//       .writeText(projectInfo.value.apiKey)
//       .then(() => {
//         console.log('Text copied to clipboard')
//         toast.success('Copy API key success!')
//       })
//       .catch((err) => {
//         console.error('Failed to copy text: ', err)
//         toast.error('Copy API key fail!')
//       })
//   } else {
//     toast.error('Copy API key fail!')
//     console.error('Clipboard API not available')
//   }
// }
async function getDataInital() {
  await getProjectInfoById()
  await getPlanUsage()
}
watch(selectedProjectId, () => {
  getDataInital()
})
function changeStatusActiveProject() {
  // display modal confirm
  if(projectInfo.value.isActive == false){
    // inactive to active
    availableProjectUsage.value--
    projectInfo.value.isActive = true
  }else{
    // active to inactive
    availableProjectUsage.value++
    projectInfo.value.isActive = false
  }
}
function changeStatus3rdParty(name) {
  let index = null
  switch (true) {
    case name === 'TASK':
      index = 0
      break
    case name === 'NOTIFICATION':
      index = 1
      break
    case name === 'DESIGN':
      index = 2
      break
    default:
      index = null
  }
  projectInfo.value.thirdPartyProjects[index].isActive = !projectInfo.value.thirdPartyProjects[index].isActive
  console.log(projectInfo.value.thirdPartyProjects[index])
}
let availableProjectUsage = ref(0)
async function getPlanUsage(){
  const res = await requestAsync.get('user-plan/' + userInfor?.userId)

  if(res?.appData){
    availableProjectUsage.value = res.appData.limitProject - res.appData.usedProject
    if(projectInfo.value.isActive == false && availableProjectUsage.value == 0){
      toast.info("Your plan is out of usage for active project, please upgrade your plan or inactive another project !")
    }
  }
}
onMounted(() => {
  getDataInital()
})
</script>

<style scoped>
.title-name {
  font-size: 20px;
  font-weight: bold;
}

.sub-title-name {
  font-size: 16px;
}

.note {
  font-size: 12px;
  color: red;
}

.btn {
  min-width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.space {
  width: 120px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading {
  animation: rotate 2s linear;
}
</style>

<template>
  <div class="instruction h-screen w-auto overflow-y-auto border border-gray-300 p-8 m-8">
    <h2 class="text-4xl text-center mb-4">Instruction</h2>
    <br>
    <p>This instruction will show you how to configuration all magic point for your project and how to use magic point tool, please follow these steps and turn on video subtitle. </p>
    <br>
    <p><b>1. Config Magic Point Project</b></p>
    <br>
    <iframe width="960" height="480" src="https://www.youtube.com/embed/eDWE1n5rR5M?si=FR0cAhCW4eKcveXm"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    <br>
    <p><b>2. Install & Config SDK</b></p>
    <br>
    <iframe width="960" height="480" src="https://www.youtube.com/embed/uRmu8BtRPpY?si=lAoJbjzzrWUA37KN"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    <br>
    <p><b>3. Create bug by manual</b></p>
    <br>
    <iframe width="960" height="480" src="https://www.youtube.com/embed/CKYydxW0qpY?si=8TXoSWui5vf50MQ8"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    <br>
    <p><b>4. Create bug by compare Figma file</b></p>
    <br>
    <iframe width="960" height="480" src="https://www.youtube.com/embed/e71rFHHKSqU?si=sN9e1cTVacPnBJlr"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
    <br>
  </div>
</template>
<style>
.instruction::-webkit-scrollbar {
  width: 8px;
}

.instruction::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.instruction::-webkit-scrollbar-thumb {
  background: #2196F3;
  border-radius: 5px;
}

.instruction::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<template>
    <div id="proof-container_proof-card"
        class="min-w-72 max-w-72 px-6 pt-24 pb-6 rounded-2xl flex flex-col gap-4 items-center justify-between relative bg-slate-200">
        <div id="proof-card_avt"
            class=" h-36 w-36 border rounded-full shadow-lg absolute -top-18 overflow-hidden flex justify-center items-center"
            :class="option && imageOptions[option] && imageOptions[option].bgColor ? `bg-[${imageOptions[option].bgColor}]` : ''">
            <img :src="avtUrl" alt="" :class="option && imageOptions[option] ? imageOptions[option].maxW : ''">
        </div>
        <p class=" opacity-50 text-black italic max-w-52 tracking-tight">“{{
            customerQuote }}”
        </p>
        <div id="customer-infor" class=" font-bold flex flex-col items-center justify-center gap-1">
            <p id="customer-name" class=" text-lp-main leading-5 text-center">{{ customerName || 'Customer Name' }}</p>
            <p id="customer-position" class=" leading-5 opacity-50 text-wrap text-center">
                {{ customerPosition || 'Customer Position' }}
                @ {{ customerCompany || 'Company' }}</p>
        </div>
    </div>
</template>
<script setup>
import { defineProps } from 'vue';
defineProps({ avtUrl: { type: String, required: true }, customerQuote: { type: String, required: true }, customerName: { type: String, required: true }, customerPosition: { type: String, required: true }, customerCompany: { type: String, required: true }, option: { type: String, required: false } })
const imageOptions = {
    normal: {
        maxW: 'max-w-36',
    },
    smaller: {
        maxW: 'max-w-28',
        bgColor: '#000d4f'
    }
}
</script>

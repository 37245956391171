import { createRouter, createWebHistory } from 'vue-router'

import LoginPage from '/src/views/LoginPage.vue'
import ForgetPassword from '/src/views/ForgetPassword.vue'
import ProjectManagement from '/src/views/ProjectManagement.vue'
import TaskManagement from '/src/views/TaskManagement.vue'
import SelectProject from '/src/views/SelectProject.vue'
import PersonalInformation from '/src/views/PersonalInformation.vue'
import UserInstructionVue from '@/views/UserInstruction.vue'
import ActiveProject from '@/views/ActiveProject.vue'
import TesterManagement from '@/components/TesterManagement.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
    },
    {
      path: '/forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword,
    },
    {
      path: '/project-management',
      name: 'ProjectManagement',
      component: ProjectManagement,
    },
    {
      path: '/task-management',
      name: 'TaskManagement',
      component: TaskManagement,
    },
    {
      path: '/select-project',
      name: 'SelectProject',
      component: SelectProject,
    },
    {
      path: '/tester-management',
      name: 'TesterManagement',
      component: TesterManagement
    },
    {
      path: '/active-project',
      name: 'Active project',
      component: ActiveProject,
    },
    {
      path: '/personal-information',
      name: 'PersonalInformation',
      component: PersonalInformation,
    },
    {
      path: '/user-instruction',
      name: 'UserInstructionVue',
      component: UserInstructionVue,
    },
    {
      path: '/',
    },
  ],
})
export default router

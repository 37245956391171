<template>
    <div ref="faq" id="faq"
        class=" max-h-28 flex flex-col justify-start rounded-2xl border border-black border-opacity-20 p-6 mb-3 transition-m-h ease-linear duration-500 delay-0 overflow-hidden hover:cursor-pointer"
        @click="toggleFaqContent">
        <div id="faq-header" class="w-full flex justify-between items-center">
            <h3 id="header-text" :title="headline" class=" text-2xl font-bold text-ellipsis line-clamp-2">{{ headline }}
            </h3>
            <div id="header-toggle-btn"
                class=" shrink-0 ml-2 w-12 h-12 rounded-full bg-lp-main p-2 hover:cursor-pointer -rotate-2">
                <img :src="arrowDown" alt="" class=" transition-all ease-out duration-500 max-h-12 delay-200"
                    :class="isOpen ? 'rotate-180' : ''">
            </div>
        </div>
        <div ref="answerRef" id="answer" class="translate-y-0 opacity-100 visible flex flex-col mt-6 justify-between">
            <hr class="bg-black border-none h-1px shrink-0 grow-0 basis-[1px]">
            <p class=" mt-6 text-wrap">{{ answer }}</p>
        </div>
    </div>
</template>
<script setup>
import { defineProps, ref, onMounted } from 'vue';
import arrowDown from '../../assets/svg/landing-page/faqs/arrow-down.svg'

defineProps({ headline: { type: String, required: true }, answer: { type: String, required: true } })
let isOpen = ref(false)
const faq = ref()
const answerRef = ref()
onMounted(() => {
    answerRef.value.style.display = "none"
})
const toggleFaqContent = () => {
    if (isOpen.value) {
        setTimeout(() => {
            answerRef.value.style.display = "none"
        }, 500);
    } else {
        answerRef.value.style.display = "block"
    }
    faq.value.classList.toggle('max-h-[30rem]')
    faq.value.classList.toggle('max-h-28')
    isOpen.value = !isOpen.value
}
</script>

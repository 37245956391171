<template>
    <section id="faq-section" class=" flex justify-center items-center py-24">
        <div id="faq" class=" w-8/12 flex flex-col justify-center items-center gap-16">
            <h2 id="faq-title" class=" text-4xl font-bold">Frequently Asked Questions (FAQs)</h2>
            <div id="faqs-container" class=" w-full">
                <template v-for="({ headline, answer }, index) of faqInfos" :key="index">
                    <LandingPageFaq :headline="headline" :answer="answer"></LandingPageFaq>
                </template>
            </div>
        </div>
    </section>
</template>
<script setup>
import LandingPageFaq from './LandingPageFaq.vue';
const faqInfos = [
    { headline: 'How does Magic-Point aid in UI testing?', answer: 'Magic-Point offers both auto and manual UI testing capabilities, enabling efficient bug detection and resolution.' },
    { headline: 'Can Magic-Point integrate with third-party task management tools?', answer: 'Absolutely! Magic-Point seamlessly connects with various task management tools, allowing users to create tasks with just one click.' },
    { headline: 'How user-friendly is Magic-Point for teams with varying technical expertise?', answer: 'Magic-Point boasts an intuitive interface designed to cater to users of all skill levels, ensuring easy adoption and smooth operation.' },
    { headline: 'Does Magic-Point support automated task creation for identified UI bugs?', answer: 'Yes, Magic-Point automates the task creation process, reducing manual effort and ensuring prompt resolution of UI bugs.' },
    { headline: 'What benefits does Magic-Point offer for workflow efficiency?', answer: 'Magic-Point streamlines the UI testing process, improving workflow efficiency by automating bug detection and task creation, ultimately leading to faster software delivery.' },
    { headline: 'Can Magic-Point highlight specific elements or components that are not aligned with the Figma designs?', answer: 'Yes, Magic-Point provides detailed visual comparisons, highlighting specific elements or components on the live website that deviate from the corresponding designs in Figma.' },

]
</script>

import { createStore } from 'vuex'
import user from './modules/user.js'
import projects from './modules/projects.js'

// Create a new store instance.
const store = createStore({
  modules: {
    user,
    projects,
  },
})
export default store

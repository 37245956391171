<!-- <template>
  <div id="forget-password" class="w-full max-w-md mx-auto mt-32 mb-10">
    <h1 class="text-4xl text-center">Forget Password</h1>
    <p class="mt-8 text-xs text-center text-gray-600">
      Please input your email, we will send new password to your email!
    </p>
    <div class="mt-8">
      <div class="mb-6">
        <label
          for="email"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Your email</label
        >
        <input
          v-model="emailInput"
          type="email"
          id="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Please enter your mail"
          required
        />
      </div>
      <div class="list-btn flex justify-between items-end">
        <button
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          @click="submitEmail()"
        >
          Submit
        </button>
        <router-link to="/login" @click="backToLoginPage()"
          >Back to login</router-link
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
// import validateEmail from '/src/ultils/validateData.js'
import { ref } from 'vue'

let emailInput = ref('')
function submitEmail() {
  // const validateInputEmail = validateEmail(emailInput)
  console.log('validateInputEmail')
}
function backToLoginPage() {}
</script> -->
<template>
  <div id="forget-password"></div>
</template>

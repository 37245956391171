<template>
<aside>
    <div class="list-page h-full pt-1 w-56">
        <template v-for="(group, id) in listPage" :key="id">
            <div class="group-features">
                <div class="gr-name">
                    {{ group.name }}
                </div>
                <div class="feature" v-for="(page, id) in group.listFeature" :key="id">
                    <button @click="changePage(page?.url)" class="page-item" :class="{
            'bg-main-theme text-white': page.url !== route.path,
            'bg-white': page.url === route.path,
        }">
                        <img :src="page.imgUrl">
                        <span :class="{
            'color-main font-bold': page.url === route.path,
            'color-white': page.url !== route.path,
        }">{{ page.name }}</span>
                    </button>
                </div>
            </div>
        </template>
    </div>
</aside>
</template>
<script setup>
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
// for images
import dashboardImg from '@/assets/images/menu/dashboard.png';
import configImg from '@/assets/images/menu/config.png';
import taskImg from '@/assets/images/menu/task.png';
import instructionImg from '@/assets/images/menu/instruction.png';
import infoImg from '@/assets/images/menu/info.png';
import logoutImg from '@/assets/images/menu/logout.png';
import users from '@/assets/images/menu/users.png';

import { useStore } from 'vuex'
import clearLocalStore from '/src/ultils/clearLocalStore.js'
const store = useStore()

const router = useRouter()
const route = useRoute()
const listPage = [
    {
        name: 'Project Setting',
        listFeature: [{
            name: 'Dashboard',
            imgUrl: dashboardImg,
            url: '/dashboard',
        },
        {
            name: 'Config',
            imgUrl: configImg,
            url: '/project-management',
        },
        {
            name: 'Task',
            imgUrl: taskImg,
            url: '/task-management',
        },
        {
            name: 'Tester List',
            imgUrl: users,
            url: '/tester-management'
        },
        // {
        //     name: 'User Instruction',
        //     imgUrl: instructionImg,
        //     url: '/user-instruction',
        // },
        ],
    },
    {
        name: 'Personal',
        listFeature: [{
            name: 'Information',
            imgUrl: infoImg,
            url: '/personal-information',
        },
        {
            name: 'Logout',
            imgUrl: logoutImg,
            url: '/logout',
        },
        ],
    }
]
function changePage(url) {
    if (url === '/logout') {
        logout()
        return
    }
    router.push(url)
}
function logout() {
    store.commit('projects/clearData');
    clearLocalStore()
    router.push('/login')
}
</script>
<style lang="scss" scoped>
@import url("@/assets/variables.css");

.list-page {
    background-color: var(--main-theme-color);
    border-right: solid 2px var(--main-theme-color);
}

.gr-name {
    text-decoration: underline;
    color: white;
    font-size: 18px;
    margin: 20px 0px 10px 15px;
}

.feature {
    .page-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 15px 15px;

        span {
            margin-left: 10px;
        }
    }

    :hover {
        img {
            width: 36px;
        }

        font-size: 18px;
        transition: 0.5s;
    }
}
</style>

import axios from 'axios'
import store from '../store'
const createRequester = function (reqUrl, reqTimeOut) {
  const axiosClient = axios.create({
    baseURL: reqUrl,
    timeout: reqTimeOut,
  })
  const post = async function (path, reqObj) {
    reqObj = reqObj || {}
    // eslint-disable-next-line no-return-await
    return await doRequest('post', path, reqObj)
  }
  const put = async function (path, reqObj) {
    reqObj = reqObj || {}
    // eslint-disable-next-line no-return-await
    return await doRequest('put', path, reqObj)
  }
  const get = async function (path, reqObj) {
    reqObj = reqObj || {}
    // eslint-disable-next-line no-return-await
    return await doRequest('get', path, reqObj)
  }
  const del = async function (path, reqObj) {
    reqObj = reqObj || {}
    // eslint-disable-next-line no-return-await
    return await doRequest('delete', path, reqObj)
  }
  const doRequest = async function (method, path, reqObj) {
    // Check Internet Explorer browser
    if (
      method === 'get' &&
      (navigator.userAgent.indexOf('MSIE') !== -1 ||
        !!document.documentMode === true)
    ) {
      // add no-cache option
      axiosClient.defaults.headers.common['Cache-Control'] =
        'no-cache, no-store'
      axiosClient.defaults.headers.common['Expires'] = -1
    }
    // Set request language
    // axiosClient.defaults.headers.common['Accept-Language'] =
    //   store.getters['lang/getSelectedLang']

    // Add token to request header except for login api
    let authToken = store.getters['user/getToken']
    if (authToken && (typeof authToken === 'string' || authToken instanceof String)) {
      axiosClient.defaults.headers.common['X-Auth-Token'] = authToken
    } else {
      axiosClient.defaults.headers.common['X-Auth-Token'] = ''
    }

    try {
      const response = await axiosClient[method](path, reqObj)
      if (response.data.hasError) {
        throw new Error(response.data.message || 'message.BOOK_SERVICE_ERROR')
      }
      return response.data
    } catch (error) {
      // if (error.response && error.response.status === 401) {
      //   store.commit('login/LOG_OUT')
      //   router.push('/error')
      //   return
      // }
      return {
        hasError: true,
        message: error.message ? error.message : 'Unexpected Error',
        appData: null,
      }
    }
  }
  return {
    post: post,
    put: put,
    get: get,
    del: del,
  }
}

const requestAsync = createRequester('https://api.mazikpoint.com/api/', 3600000)
export default requestAsync

const initialState = {
  listProject: [],
  selectedProjectInfo: null,
  selectedProjectId: 0,
}

const localState = localStorage.getItem('projects')
if (localState) {
  Object.assign(initialState, JSON.parse(localState))
}
const getters = {
  getListProject: (state) => state.listProject,
  getSelectedProjectId: (state) => state.selectedProjectId,
  getSelectedProjectInfo: (state) => state.selectedProjectInfo
}
const mutations = {
  setListProject: (state, listProject) => {
    state.listProject = listProject
    console.log(state)
    localStorage.setItem('projects', JSON.stringify(state))
  },
  addProjectToList: (state, project) =>{
    state.listProject.push(project)
    localStorage.setItem('projects', JSON.stringify(state))
  },
  setSelectedProjectId: (state, selectedProjectId) => {
    state.selectedProjectId = selectedProjectId
    console.log(state)
    localStorage.setItem('projects', JSON.stringify(state))
  },
  setSelectedProjectInfo: (state, selectedProjectInfo) => {
    state.selectedProjectInfo = selectedProjectInfo
    console.log(state)
    localStorage.setItem('projects', JSON.stringify(state))
  },
  clearData: (state) => {
    state.listProject = []
    state.selectedProjectId = 0
  },
}
export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
}

<template>
<section id="features"
    class="flex flex-col gap-16 justify-between items-center h-full pb-24">
    <h2 id="features_title" class=" text-4xl font-bold">We provide awesome
        services</h2>
    <div id="features_feature-card-group" class="flex w-full justify-evenly">
        <template
            v-for="({ iconUrl, cardTitle, cardContent, buttonContent }, index) of cardInfos"
            :key="index">
            <LandingPageFeatureCard :cardIconUrl="iconUrl"
                :cardTitle="cardTitle" :cardContent="cardContent"
                :buttonContent="buttonContent">
            </LandingPageFeatureCard>
        </template>
    </div>
    <LandingPageButton :href="'https://chromewebstore.google.com/detail/magic-point/aneeijmdeahjkiegfhgngfbkcopllngo'"
        :buttonContent="'Learn more'">
    </LandingPageButton>
</section>
</template>
<script setup>
import LandingPageButton from './LandingPageButton.vue'
import LandingPageFeatureCard from './LandingPageFeatureCard.vue'
import featureEasy from '../../assets/svg/landing-page/feature-icons/feature-easy.svg'
import multiplePlatform from '../../assets/svg/landing-page/feature-icons/multiple-platform.svg'
import superFast from '../../assets/svg/landing-page/feature-icons/super-fast.svg'
import coin from '../../assets/svg/landing-page/feature-icons/coin.svg'
const cardInfos = [
    { iconUrl: featureEasy, cardTitle: 'Effortless Testing Process', cardContent: 'Our tool offers a highly user-friendly testing experience, enabling users to easily spot bugs and create tasks with just one click.' + '\n' + 'This efficiency allows users to concentrate on enhancing their UI without the burden of cumbersome tasks, thereby improving testing efficiency with minimal effort.', buttonContent: 'Learn more' },
    { iconUrl: multiplePlatform, cardTitle: 'Multi-Platform Integration', cardContent: 'Seamlessly integrate with top task management tools, including Nulab and Jira. Our solution enhances workflow flexibility, connecting you with platforms you trust for a streamlined and efficient project management experience across your preferred tools.', buttonContent: 'Learn more' },
    { iconUrl: superFast, cardTitle: 'Lightning-Fast Bug Tracking', cardContent: 'Revolutionize bug tracking with our tool, designed for ultra-fast detection and automated task creation. Streamline your workflow, quickly identifying bugs and generating tasks, boosting productivity. Experience unparalleled efficiency in your testing journey, from spotting bugs to resolution.', buttonContent: 'Learn more' },
    { iconUrl: coin, cardTitle: 'Cost-Effective Efficiency', cardContent: 'By automating bug detection and task creation, you eliminate manual overhead, saving valuable time and reducing costs. Our solution ensures that every aspect of your project management is optimized for both speed and savings', buttonContent: 'Learn more' },
]
</script>

<template>
<div id="user-management" class="grow m m-6">
    <p id="tester-management-title" class=" text-4xl text-center mb-4">Tester Management</p>
    <button type="button" @click="showAddTesterModal" v-show="isAllowToAddTester"
        class=" mb-2 w-max h-max shrink-0 flex items-center justify-center text-white bg-blue-600 hover:bg-primary-800 focus:ring-4 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none">
        <svg class="h-5 w-5 mr-2" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true">
            <path clip-rule="evenodd" fill-rule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
        </svg>
        {{ $t('testerManagement.ADD_TESTER') }}
    </button>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('testerManagement.TableHeader.UserName') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('testerManagement.TableHeader.Email') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ $t('testerManagement.TableHeader.Status') }}
                    </th>
                    <!-- <th scope="col" class="px-6 py-3">
                        Action
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <template v-for="(user, idx) of listUserByProjectId" :key="idx">
                    <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800">
                        <th scope="row"
                            class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="ps-3">
                                <div class="text-base font-semibold">{{ user.name ?? user.email.split('@')[0] }}</div>
                            </div>
                        </th>
                        <td class="px-6 py-4">
                            <div class="font-normal text-gray-500">{{ user.email }}</div>
                        </td>
                        <td class="px-6 py-4">
                            <div class="flex items-center">
                                <div class="h-2.5 w-2.5 rounded-full  me-2"
                                    :class="user.isActivated ? 'bg-green-500' : 'bg-red-500'">
                                </div>
                                {{ user.isActivated ? 'Activated' : 'Inactivated' }}
                            </div>
                        </td>
                    </tr>
                </template>


            </tbody>
        </table>
    </div>
</div>
<fwb-modal persistent v-show="isOpenAddTesterModal">
    <template #header>
        <div class="flex items-center text-2xl text-center mx-auto">
            {{ $t('testerManagement.ADD_TESTER') }}
        </div>
    </template>
    <template #body>
        <div class="w-90 max-w-[500px] mx-auto flex justify-center items-center mr-20">
            <label for="email" class="inline-block text-xl font-medium text-gray-900 dark:text-white basis-1/6">{{
        $t('testerManagement.EMAIL') }}:</label>
            <input v-model="testerEmail" type="email" id="email"
                class="basis-4/6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required />
        </div>
        <div class=" w-full flex justify-center items-center mt-2 transition-none duration-500 ease-out">
            <p :class="messageObj.isError ? `text-red-700 !opacity-100` : `text-green-700 !opacity-100`">{{
        messageObj.message
    }}</p>
        </div>
    </template>
    <template #footer>
        <div class="flex justify-between">
            <fwb-button @click="adminAddTester" class="magic-point-btn">
                {{ $t('testerManagement.Submit') }}
            </fwb-button>
            <fwb-button @click="hideAddTesterModal" color="alternative">
                {{ $t('testerManagement.Cancel')}}
            </fwb-button>
        </div>
    </template>
</fwb-modal>
</template>
<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import requestAsync from '@/request/request';
import { FwbModal, FwbButton } from 'flowbite-vue';
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify'
import { validateEmail } from '@/ultils/validateData';
import { isProjectOwner } from '@/ultils/validateData';

const store = useStore()
let selectedProjectId = computed(() => {
    return store.getters['projects/getSelectedProjectId']
})

const listUserByProjectId = ref([])
const getUserListByProjectId = async (projectId) => {
    const res = await requestAsync.get(`user/tester-list/${projectId}`)
    console.log(res)
    listUserByProjectId.value = res.appData
}

onMounted(() => {
    getUserListByProjectId(selectedProjectId.value)
})

watch(selectedProjectId, () => {
  getUserListByProjectId(selectedProjectId.value)
})


// admin add tester
const isOpenAddTesterModal = ref(false)
const showAddTesterModal = () => {
    isOpenAddTesterModal.value = true
}
const hideAddTesterModal = () => {
    isOpenAddTesterModal.value = false
}
let testerEmail = ref("")
const messageObj = ref({ isError: false, message: '' })
const adminAddTester = async () => {
    console.info('admin add tester')
    if (!validateEmail(testerEmail.value)) {
        messageObj.value.message = "Please enter a valid email address!"
        messageObj.value.isError = true
        resetMessage()
        return
    }

    const reqData = {
        appData: {
            testerEmail: testerEmail.value,
            projectId: selectedProjectId.value
        }
    }

    const res = await requestAsync.post('user/admin-add-tester-to-project', reqData)

    if (res && !res.hasError) {
        hideAddTesterModal()
        await getUserListByProjectId(selectedProjectId.value)
        toast.success('Add tester to project success')
      testerEmail.value = ""
    } else {
        messageObj.value.message = "User already added to this project"
        messageObj.value.isError = true
    }
    resetMessage()
}
const resetMessage = () => {
    setTimeout(() => {
        messageObj.value.message = ""
        messageObj.value.isError = false
    }, 3000);
}

let userInfor = computed(() => {
    return store.getters['user/getUserInfo']
}).value

let selectedProjectInfo = computed(() => {
    return store.getters['projects/getSelectedProjectInfo']
})

let isAllowToAddTester = computed(() => {

    // handle null error userInfo when logout and not allow to add tester when creating project
    if(userInfor == null || selectedProjectInfo.value == null){
        return false
    }

    return isProjectOwner(userInfor?.userId, selectedProjectInfo?.value.userId)
})

</script>
<style></style>

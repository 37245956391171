export default {
  MAGIC_POINT_TITLE: 'Magic Point Manage',
  PROJECT_MANAGEMENT_TITLE: 'Project management',
  TASK_MANAGEMENT_TITLE: 'Task management',
  THIRD_PARTY_TITLE: 'Third party integration',
  USER_INSTRUCTION_TITLE: 'User Instruction',
  SUBMIT_BTN: 'Submit',
  CANCEL_BTN: 'Cancel',
  UPDATE_BTN: 'Update',
  CHANGE_PASSWORD: 'Change password',
}

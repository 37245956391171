export default {
  TITLE: 'Personal Information',
  NAME: 'Name',
  EMAIL: 'Email',
  ROLE: 'Role',
  API_KEY: 'API KEY',
  UPDATE_INFORMATION: 'Update information',
  OLD_PASSWORD: 'Old password',
  NEW_PASSWORD: 'New password',
  CONFIRM_PASSWORD: 'Confirm password',
}

<template>
  <section id="task-management" class="px-4 mt-3 flex flex-col">
    <h2 class="text-4xl text-center my-4">{{ $t('taskManagement.TASK_MANAGEMENT') }}</h2>
    <hr class="my-3" />
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                      {{ $t('taskManagement.NUMBER') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{ $t('taskManagement.TITLE') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{ $t('taskManagement.LINK_TASK') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{ $t('taskManagement.STATUS') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{ $t('taskManagement.CREATOR') }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                      {{ $t('taskManagement.CREATED_DATE') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, idx) of listTask" :key="idx">
                    <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800">
                        <td class="px-6 py-3">
                          <div class="text-base font-semibold">{{ idx + 1 }}</div>
                        </td>
                        <td class="px-6 py-3">
                          {{ item?.title }}
                        </td>
                        <td class="px-6 py-3">
                          <div class="font-normal text-blue-500"><a :href="item['taskUrl']" target="_blank">{{item['taskUrl']}}</a></div>
                        </td>
                        <td class="px-6 py-3">
                          {{ item?.status }}
                        </td>
                        <td class="px-6 py-3">
                          {{ item?.creator}}
                        </td>
                        <td class="px-6 py-3">
                          {{ item['created-date'] }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
    <div v-if="isLoading" class="custom-spinner">
      <VueSpinner size="50" color="#0B60B0"/>
    </div>
    <div v-if="isEmptyTask" class="text-1xl text-center my-4">
      <p>This project have no tasks</p>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import requestAsync from '/src/request/request.js'
import {VueSpinner} from 'vue3-spinners';
const store = useStore()
let isLoading = false
let listTask = ref([])
let isEmptyTask = ref(false)

let selectedProjectId = computed(() => {
  return store.getters['projects/getSelectedProjectId']
})
async function getListTask() {
  isEmptyTask.value = false
  isLoading = true
  listTask.value = []
  if (selectedProjectId.value) {
    const res = await requestAsync.get('tasks/' + selectedProjectId.value)
    if (!res?.hasError && res?.appData) {
      listTask.value = res?.appData.map((item) => {
        return {
          title: item?.title,
          taskUrl: item?.taskUrl,
          status: item?.taskStatus?.name,
          'created-date': item?.createdDate,
          creator: item?.creator?.email
        }
      })
    }
  }
  if(listTask.value.length <= 0){
    isEmptyTask.value = true
  }
  isLoading = false
}
watch(selectedProjectId, () => {
  return getDataInital()
})
function getDataInital() {
  getListTask()
}
onMounted(() => {
  getDataInital()
})
</script>
<style>
.Pagination {
  justify-content: center;
  margin: 10px 0px 20px !important;
}
.custom-spinner{
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
</style>

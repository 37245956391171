<template>
    <section id="footer-section" class="bg-slate-500 flex justify-center items-center py-24">
        <div id="footer-container" class=" w-lp-secondary flex flex-col justify-between gap-11">
            <div id="row-1" class=" w-full flex justify-between items-center">
                <a href="#" class=" h-20">
                    <img src="../../assets/images//Magic_point_Logo_V3.png" alt="" class=" max-h-20">
                </a>
                <div id="copyright" class=" text-white">© 2018 - 2024 GenkiSystem Inc.</div>
                <div id="social-media" class="flex justify-between">
                    <template v-for="({ name, imgUrl, href }, index) in socialMediaInfo" :key="index">
                        <a target="_blank" :href="href" class=" w-9 mx-3 first:ml-0 last:mr-0">
                            <img :src="imgUrl" :id="name" class="border rounded-full border-white/35" />
                        </a>
                    </template>
                </div>
            </div>
            <hr class=" h-[1px] bg-white w-full opacity-50">
            <div id="row-2" class="w-2/3 max-w-3xl flex justify-between items-center">
                <div id="col-1" class=" flex flex-col justify-start items-start gap-6 text-white opacity-50 h-full">
                    <div id="about-us">About: <a target="_blank" href="https://vn.genkisystem.com/en/"
                            class="hover:underline">GENKI SYSTEM</a></div>
                    <div id="contact">Contact: <a href="tel:0866541800" class="hover:underline">0866541800</a></div>
                    <div id="address">Address: <a target="_blank"
                            href="https://www.google.com/maps/place/39-41+L%C3%AA+Th%E1%BA%A1ch,+Ph%C6%B0%E1%BB%9Dng+12,+Qu%E1%BA%ADn+4,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh+700000,+Vi%E1%BB%87t+Nam/@10.7638799,106.7037397,17z/data=!3m1!4b1!4m6!3m5!1s0x31752f697bb578a7:0xf058c6236725543a!8m2!3d10.7638799!4d106.7063146!16s%2Fg%2F11nnt8vsbf?hl=vi-VN&entry=ttu"
                            class="hover:underline">39-41 Le Thach Street, Ward 12, District 4, HCM City</a></div>
                </div>
                <div id="col-2" class=" flex flex-col justify-start items-start gap-6 text-white opacity-50 h-full">
                    <a href="#features" class="hover:underline">Easy to use</a>
                    <a href="#features" class="hover:underline">Super Fast</a>
                    <a href="#features" class="hover:underline">Multiple Platform Support</a>
                </div>
                <div id="col-3" class=" flex flex-col justify-start items-start gap-6 text-white opacity-50 h-full">
                    <a href="#features" class="hover:underline">Savings</a>
                    <a href="#features" class="hover:underline h-6"></a>
                    <a href="#features" class="hover:underline h-6"></a>
                </div>

            </div>
        </div>
    </section>
</template>
<script setup>
import fb from '../../assets/svg/landing-page/social-media-icons/fb.png'
import insta from '../../assets/svg/landing-page/social-media-icons/insta.png'
import linkedin from '../../assets/svg/landing-page/social-media-icons/linkedin.webp'
const socialMediaInfo = [
    { name: 'facebook', imgUrl: fb, href: 'https://www.facebook.com/profile.php?id=100063564523582' },
    { name: 'instagram', imgUrl: insta, href: 'https://www.instagram.com/genki_system' },
    { name: 'linkedin', imgUrl: linkedin, href: 'https://www.linkedin.com/company/genki-system' },
]
</script>

<template>
  <section id="personal-information" class="w-full px-4 mt-3 flex flex-col">
    <h2 class="text-4xl">{{ $t('personalInformation.TITLE') }}</h2>
    <hr class="my-3" />
    <div class="mt-12">
      <div class="mx-auto mb-6 max-w-[400px]">
        <label
          for="email"
          class="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
          >{{ $t('personalInformation.NAME') }}</label
        >
        <input
          v-model="nameInput"
          type="email"
          id="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
        <label
          for="email"
          class="block mt-4 mb-2 text-xl font-medium text-gray-900 dark:text-white"
          >{{ $t('personalInformation.EMAIL') }}</label
        >
        <input
          v-model="emailInput"
          disabled
          type="email"
          id="email"
          class="bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
        <label
          for="email"
          class="block mt-4 mb-2 text-xl font-medium text-gray-900 dark:text-white"
          >{{ $t('personalInformation.ROLE') }}</label
        >
        <input
          v-model="roleInput"
          disabled
          type="email"
          id="email"
          class="bg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
        <!-- <label for="email" class="block mt-4 mb-2 text-xl font-medium text-gray-900 dark:text-white">{{ $t('personalInformation.API_KEY') }}</label>
        <input v-model="apiKey" type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required> -->
      </div>
      <div class="list-btn flex flex-row justify-center">
      <button class="magic-point-btn" @click="updateUserInfor()">
        {{ $t('common.UPDATE_BTN') }}
      </button>
      <button class="magic-point-btn ml-5" @click="showModalChangePassword()">
        {{ $t('common.CHANGE_PASSWORD') }}
      </button>
    </div>
    </div>
    <!-- <fwb-modal
      persistent
      v-if="isShowModalUpdateInfor"
      @close="closeModalUpdateInfor"
    >
      <template #header>
        <div class="flex items-center text-2xl text-center mx-auto">
          {{ $t('personalInformation.UPDATE_INFORMATION') }}
        </div>
      </template>
      <template #body>
        <div class="w-90 max-w-[500px] mx-auto flex flex-col justify-center">
          <label
            for="email"
            class="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
            >{{ $t('personalInformation.NAME') }}</label
          >
          <input
            v-model="nameInputChange"
            type="email"
            id="email"
            class="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <fwb-button @click="updateUserInfor()" class="magic-point-btn">
            Submit
          </fwb-button>
          <fwb-button @click="closeModalUpdateInfor" color="alternative">
            Cancel
          </fwb-button>
        </div>
      </template>
    </fwb-modal> -->
    <fwb-modal
      persistent
      v-if="isShowModalChangePassword"
      @close="closeModalChangePassword"
    >
      <template #header>
        <div class="flex items-center text-2xl text-center mx-auto">
          {{ $t('personalInformation.UPDATE_INFORMATION') }}
        </div>
      </template>
      <template #body>
        <div class="w-90 max-w-[500px] mx-auto flex flex-col justify-center">
          <label
            for="email"
            class="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
            >{{ $t('personalInformation.OLD_PASSWORD') }}</label
          >
          <input
            v-model="currentPassword"
            type="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
          <label
            for="email"
            class="block mt-3 mb-2 text-xl font-medium text-gray-900 dark:text-white"
            >{{ $t('personalInformation.NEW_PASSWORD') }}</label
          >
          <input
            v-model="newPassword"
            type="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
          <label
            for="email"
            class="block mt-3 mb-2 text-xl font-medium text-gray-900 dark:text-white"
            >{{ $t('personalInformation.CONFIRM_PASSWORD') }}</label
          >
          <input
            v-model="confirmNewPassword"
            type="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
          />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <fwb-button @click="changePassword" class="magic-point-btn">
            Submit
          </fwb-button>
          <fwb-button @click="closeModalChangePassword" color="alternative">
            Cancel
          </fwb-button>
        </div>
      </template>
    </fwb-modal>
  </section>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { FwbButton, FwbModal } from 'flowbite-vue'
import requestAsync from '/src/request/request.js'
import { useStore } from 'vuex'
import router from '../router'
import { toast } from 'vue3-toastify'
import clearLocalStore from '/src/ultils/clearLocalStore.js'
const store = useStore()

//let nameInputChange = ref('')
let emailInput = ref('')
let nameInput = ref('')
let roleInput = ref('')
let apiKey = ref('')
let currentPassword = ref('')
let newPassword = ref('')
let confirmNewPassword = ref('')

// const isShowModalUpdateInfor = ref(false)

// function closeModalUpdateInfor() {
//   isShowModalUpdateInfor.value = false
// }
// function showModalUpdateInfor() {
//   isShowModalUpdateInfor.value = true
// }
const isShowModalChangePassword = ref(false)

function closeModalChangePassword() {
  isShowModalChangePassword.value = false
}
function showModalChangePassword() {
  isShowModalChangePassword.value = true
}
let userInfor = computed(() => {
  return store.getters['user/getUserInfo']
}).value
async function getPersonalInformation() {
  const res = await requestAsync.get('user/information/' + userInfor?.userId)
  if (!res?.hasError && res?.appData) {
    emailInput.value = res?.appData?.email
    nameInput.value = res?.appData?.name
    roleInput.value = res?.appData?.role
    apiKey.value = ''
    // nameInputChange.value = nameInput.value
  }
  else{
    console.log("get user info fail")
  }
}
async function updateUserInfor() {
  await submitUpdateInformation()
  await getPersonalInformation()
  // closeModalUpdateInfor()
}
async function submitUpdateInformation() {
  // only update name
  let reqData = {
    appData: {
      id: userInfor?.userId,
      name: nameInput?.value,
    },
  }
  const res = await requestAsync.put(
    'user/information/' + userInfor?.userId,
    reqData
  )
  if (!res?.hasError && res.appData) {
    toast.success('Update name success!')
  } else {
    toast.error('Update name fail!')
  }
}
async function changePassword() {
  let reqData = {
    appData: {
      email: emailInput?.value,
      oldPassword: currentPassword?.value,
      newPassword: newPassword?.value,
      confirmNewPassword: confirmNewPassword?.value,
    },
  }
  const res = await requestAsync.put(
    'user/update-password/' + userInfor?.userId,
    reqData
  )
  if (res?.appData && !res?.hasError) {
    // notification update successs
    toast.success('Update password success!,please login again. Redirecting to login...')
    setTimeout(() => {
      logout()
    }, 1500)
  } else {
    toast.error('Change password fail!')
  }
}

function logout(){
  store.commit('projects/clearData');
  clearLocalStore()
  router.push('/login')
}
onMounted(() => {
  getPersonalInformation()
})
</script>

import common from './common'
import projectManagement from './project-management'
import taskManagement from './task-management'
import login from './login'
import personalInformation from './personal-information'
import testerManagement from './tester-management'

export default {
  common: common,
  projectManagement: projectManagement,
  taskManagement: taskManagement,
  login: login,
  personalInformation: personalInformation,
  testerManagement: testerManagement
}

export default {
  PROJECT_NAME: 'Project name',
  PROJECT_NAME_PLACEHOLDER: 'Input your project name',
  ACTIVE_PROJECT: 'Active',
  API_KEY: 'API key',
  TASK_CHANNEL_CONFIGS: 'Connect task channel',
  SELECTED_CHANNEL: 'Selected channel',
  ACTIVE_TASK: 'Active',
  SPACE_ID: 'Space ID',
  PROJECT_KEY: 'Project key',
  USER_KEY: 'User key',
  NOTIFICATION_CHANNEL_CONFIGS: 'Connect notification channel',
  ACTIVE_NOTIFICATION: 'Active',
  WEBHOOK_URL: 'WebhookURL',
  DESIGN_TOOL_CONFIGS: 'Connect design tool',
  TEAM_ID: 'Team ID',
  ADD_MORE_BTN: 'Add more + ',
}
